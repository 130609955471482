import React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import NavigationLink from './NavigationLink'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import '../scss/base.scss'

export default function ContentSectionMediumImageAndText(props) {

	const image = getImage(props.image)
	const description = documentToReactComponents(JSON.parse(props.description.raw))

  return (
		<div className={`contentWithImage${props.imageJustify ? ' reverseJustify' : ''} ${props.background}`}>
			<div>
				<h2>{props.heading}</h2>
				<div className="sectionText">
					{description}
					{props.link && <NavigationLink link={props.link} type="secondary" />}
				</div>
			</div>
			<div>
				<GatsbyImage 
					image={image}
					alt="Laser Metal Cutting"
					loading="lazy"
					placeholder="blurred" />
			</div>
		</div>
	)
}
