import React from "react"

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import '../scss/base.scss'
import '../scss/textFeature.scss'

export default function ContentSectionRichText(props) {

	const description = documentToReactComponents(JSON.parse(props.text.raw))

  return (
		<div className="textFeatureContent">
			{description}
		</div>)
}
