import React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

import '../scss/base.scss'
import '../scss/imageCollection.scss'

export default function ContentSectionImageFeature(props) {

  return (
		<div className={`imageCollection ${props.imageSize}`}>
      {props.images.map(image => (
				<div key={image.contentful_id}>
					<GatsbyImage 
					image={getImage(image)}
					alt={props.alt ?? ""}
					loading="lazy"
					placeholder="blurred" />
				</div>
      ))}
		</div>
		)
}
