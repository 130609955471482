import React from "react"
import {graphql, Link} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import get from 'lodash/get'

import Layout from '../components/Layout'

import CenteredSection from '../components/CenteredSection'
import ContentSectionRichText from '../components/ContentSectionRichText'
import ContentSectionButton from '../components/ContentSectionButton'
import ContentSectionHeader from '../components/ContentSectionHeader'
import ContentSectionImageFeature from '../components/ContentSectionImageFeature'
import ContentSectionLandingPageHeader from '../components/ContentSectionLandingPageHeader'
import ContentSectionLargeImageAndText from '../components/ContentSectionLargeImageAndText'
import ContentSectionMediumImageAndText from '../components/ContentSectionMediumImageAndText'
import ContentSectionSmallImageAndText from '../components/ContentSectionSmallImageAndText'
import ContentSectionImageCollection from '../components/ContentSectionImageCollection'
import ContentSectionTextFeature from '../components/ContentSectionTextFeature'

import '../scss/articles.scss'

import calendarLogo from '../images/calendar.svg'
import avatarLogo from '../images/avatar2.svg'

const Articles = (props) => {

  const data = props.data
  const posts = get(data, 'allContentfulBlogPost')

  const pageNumbers = Array.from(Array(props.pageContext.numPages).keys())

  // activePage={page.slug} title={page.seoTitle} description={page.seoDescription}
  return (<Layout activePage="articles" title="OSH Cut - Sheet Metal Fab" description="OSH Cut provides news about its metal fab operations, the state of the industry, and other related topics.">
    <CenteredSection>
      <div className = "articles">
        {posts.edges.map(p => 
          <div className="articleStub" key={p.node.id}>
            <Link to={`/articles/${p.node.slug}/`}><h1>{p.node.title}</h1></Link>
            <div className="heroImageHeader">
              {!!p.node.author?.fullName && <div className="authorName"><img src={avatarLogo} />{p.node.author.fullName}{p.node.author.position && ' - ' + p.node.author.position}</div>}
              {!!p.node.publishDate && <div className="publicationDate"><img src={calendarLogo} />{p.node.publishDate}</div>}
            </div>
            {!!p.node.heroImage?.gatsbyImageData && <Link to={`/articles/${p.node.slug}/`}><GatsbyImage 
                image={getImage(p.node.heroImage.gatsbyImageData)}
                alt={p.node.heroImage.description ?? "Laser Metal Cutting"}
                loading="lazy"
                placeholder="blurred" /></Link>}
            {!!p.node.description?.description && (<div className="articleStubDescriptionText"><Link to={`/articles/${p.node.slug}/`}>{p.node.description.description}</Link></div>)}
          </div>
        )}
        <div className="articlesPageFooter">
          {props.pageContext.currentPage > 1 && 
            <Link to={props.pageContext.currentPage === 2 ? '/articles' : `/articles/${props.pageContext.currentPage-1}/`}><div className="pageNavItem">{`<`}</div></Link> 
          }
          {pageNumbers.map(num => (<>
              {props.pageContext.currentPage !== (num + 1) ?
              <Link to={num === 0 ? '/articles' : `/articles/${num + 1}`}><div className="pageNavItem">{num + 1}</div></Link>
              :
              <div className="pageNavItem active">{num + 1}</div>
            }</>)
          )}
          {props.pageContext.currentPage < props.pageContext.numPages && 
            <Link to={`/articles/${props.pageContext.currentPage+1}/`}><div className="pageNavItem">{`>`}</div></Link> 
          }
        </div>
      </div>
    </CenteredSection>
  </Layout>)
}

export default Articles

export const postListQuery = graphql`
  query PostsByArticlesPage($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(limit: $limit, skip: $skip, sort: {fields: publishDate, order: DESC}) {
      edges {
        node {
          id
          slug
          title
          seoTitle
          seoDescription
          subtitle
          publishDate(formatString: "DD MMM YYYY", locale: "MST")
          author {
            fullName
            position
          }
          heroImage {
            gatsbyImageData(width: 600, placeholder: BLURRED, quality: 80)
            description
          }
          description {
            description
          }
          content {
            raw
            references {
              __typename
              ... on ContentfulAsset {
                id
                contentful_id
                gatsbyImageData(width: 600, placeholder: BLURRED, quality: 80)
              }
              ... on ContentfulImage {
                id
                name
                source {
                  gatsbyImageData
                }
                alt
                caption
                contentful_id
                display
                size
              }
            }
          }
        }
      }
    }
  }
`
