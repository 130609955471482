import React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import NavigationLink from './NavigationLink'

import { INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import '../scss/smallImageWithText.scss'
import '../scss/base.scss'

export default function ContentSectionSmallImageAndText(props) {

	const options = {
		renderNode: {
			[INLINES.HYPERLINK]: (props) => {console.log(props); return <a href={props.data.uri} target={props.data.uri?.indexOf("oshcut") >= 0 ? '' : '_blank'}>{props.content[0].value}</a>}
		}
	}

	const image = getImage(props.image)
	const description = documentToReactComponents(JSON.parse(props.description.raw), options)

  return (
		<div className={`contentWithSmallImage${props.imageJustify ? ' reverseJustify' : ''}`}>
			<div>
				<h2>{props.heading}</h2>
				<div className="sectionText">
					{description}
					{props.link && <NavigationLink link={props.link} />}
				</div>
			</div>
			<div>
				<GatsbyImage 
					image={image}
					alt={props.image.description ?? "Laser Metal Cutting"}
					loading="lazy"
					placeholder="blurred" />
			</div>
		</div>
	)
}

