import React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

import '../scss/base.scss'

export default function ContentSectionImageFeature(props) {

	const image = getImage(props.image)

  return (
			<GatsbyImage 
				image={image}
				alt={props.alt ?? ""}
				loading="lazy"
				placeholder="blurred" />
		)
}
