import React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import CenteredSection from './CenteredSection'
import NavigationLink from './NavigationLink'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import '../scss/contentSectionLandingPageHeader.scss'

export default function ContentSectionLandingPageHeader(props) {

	const image = getImage(props.backgroundImage)

  return (
		<div className="fullWidthSection" style={{background: "#000"}}>
			<div className="landingPageHeader">
				<div className="leftMargin"></div>
				<div className="landingPageHeaderContent">
					{(props.title || props.description || props.link) && (<>
						<div className="contentArea">
							{props.title && (<h1>{props.title}</h1>)}
							{props.description && (<div>{documentToReactComponents(JSON.parse(props.description.raw))}</div>)}
							{props.link && <NavigationLink link={props.link} />}
						</div>
						<div className="gradientArea"></div>
					</>)}
				</div>
				<div className="landingPageBackgroundImage">
					<GatsbyImage 
						image={image}
						alt={props.alt ?? ""}
						loading="eager"
						placeholder="blurred"
						layout="fullWidth"
						imgStyle={{objectFit: `cover `}}
						className="landingGatsbyImage" />
				</div>
			</div>			
		</div>
		)
}

/*
<div className="landingPageHeaderContent">
						<div>
							<div>
								{props.title && (<h1>{props.title}</h1>)}
								{props.description && (<div>{documentToReactComponents(JSON.parse(props.description.raw))}</div>)}
								{props.link && <NavigationLink link={props.link} />}
							</div>
						</div>
					</div>

<div className="fullWidthBackground" style={{background: '#323F4B'}}>
				<GatsbyImage 
					image={image}
					alt={props.alt ?? ""}
					loading="eager"
					placeholder="blurred"
					layout="fullWidth"
					className={`fullWidthBackgroundImage${props.addDarkeningOverlay ? ' translucent1' : ''}`} />

				{(props.title || props.description || props.link) && (
					<div className="contentOverBackgroundImage">
						<CenteredSection colorStyle="transparent">
							<div className={`floatingContent${props.useDarkOverlay ? ' dark' : ' light'}`}>
								{props.title && (<h1>{props.title}</h1>)}
								{props.description && (<div>{documentToReactComponents(JSON.parse(props.description.raw))}</div>)}
								{props.link && <NavigationLink link={props.link} />}
							</div>
						</CenteredSection>
					</div>
				)}
			</div>
*/