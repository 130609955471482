import React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import NavigationLink from './NavigationLink'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import '../scss/base.scss'
import '../scss/contentSectionLargeImageAndText.scss'

export default function ContentSectionLargeImageAndText(props) {

	const image = getImage(props.image)
	const description = documentToReactComponents(JSON.parse(props.description.raw))

  return (
		<div className={`splitRow${props.imageJustify ? ' reverseJustify' : ''}`}>
			<div className="text">
				<div className="largeImageSectionTitle"><h2>{props.heading}</h2></div>
				{description}
				{props.link && <NavigationLink link={props.link} />}
			</div>
			<div>
				<GatsbyImage 
					image={image}
					alt={props.alt ?? ""}
					loading="lazy"
					placeholder="BLURRED" />
			</div>
		</div>
	)
}
