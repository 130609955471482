import React from "react"
import NavigationLink from './NavigationLink'

import '../scss/base.scss'

export default function ContentSectionButton(props) {

  return (
		<div className="center">
			<div style={{paddingBottom: "1.0em"}}>
				<NavigationLink link={props.link} />
			</div>
		</div>
		)
}
