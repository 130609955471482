import React from "react"

import '../scss/button.scss'

export default function GetStartedButton(props) {

  const buttonClass = `button ${props.type ?? ''} ${props.active ? 'buttonActive' : ''}`

  return (
    <button
      onClick={props.onClick}
      className={buttonClass}
      disabled={props.disabled}
      title={props.title}>
      {props.children}
    </button>
  )
}
