import React from "react"
import Button from './Button'
import {Link} from "gatsby"

import '../scss/base.scss'

export default function NavigationLink(props) {

  const type = props?.type ?? "primary"
  const text = props.link.linkText
  const isButton = props.link.isAButton
  const openInNewTab = props.link.opernInNewTab
  const pageSlug = props.link.page?.slug
  let url = props.link.url
  let target = "_self"

  if (openInNewTab) {
    target = "_blank"
  }

  if (pageSlug) {
    url = '/' + pageSlug + '/'
  }

  return (<>
    {pageSlug ? (
      <Link to={url} target={target}>
        {isButton ? <Button type={type} onClick={() => {}}>{text}</Button> : <>{text}</>}
      </Link>
    ) : (
      <a href={url} target={target}>
        {isButton ? <Button type={type} onClick={() => {}}>{text}</Button> : <>{text}</>}
      </a>
    )}
  </>)
}
